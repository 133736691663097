<template>
  <div class="page" :class="{'ratio': devicePixelRatio === 2}">
    <div class="block bar">
      <div class="bar_content">
        <div class="bar_value">
          <div class="title">VIP APPLY</div>
          <div class="hint">VIP CLIENTS</div>
          <div class="hint">Dear VIP user, based on your credit history on AjeGo, the following information hasbeen evaluated for loan availability.</div>
        </div>
      </div>
      <div class="bar_img">
        <img v-if="devicePixelRatio == 2" src="@/assets/img/apply_banner@2x.png">
        <img v-else src="@/assets/img/apply_banner.png">
      </div>
    </div>
    <div v-loading="loading">
      <el-form v-if="!accessToken" :model="form" :rules="rules" ref="form">
        <div class="block content">
          <div class="block_box">
            <div class="input">
              <span class="required">Mobile:</span>
              <el-form-item prop="phone">
                <el-input
                  placeholder="Please enter"
                  v-model="form.phone"
                  v-mask="'### ### ### ### #'">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">SMS Code:</span>
              <el-form-item prop="code">
                <el-input
                  placeholder="Please enter"
                  v-model="form.code"
                  v-mask="'####'">
                  <el-button slot="append" @click="getSmsCode('SMS')">
                    Get OTP
                    <template v-if="time">
                      ({{ time }})
                    </template>
                  </el-button>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <el-button type="primary" @click="onLogin">Get loan now or Check loan status</el-button>
        </div>
      </el-form>

      <el-form v-else-if="isApply" :model="item" :rules="rules" ref="form">
        <div class="block content">
          <div class="block_box">
            <div class="input">
              <span class="required">Quota:</span>
              <el-form-item prop="applyAmount">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.applyAmount">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Term:</span>
              <el-form-item prop="displayLoanDays">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.displayLoanDays">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Transaction amount:</span>
              <el-form-item prop="loanAmount">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.loanAmount">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Interest:</span>
              <el-form-item prop="serviceFees">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.serviceFees">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Repayment date:</span>
              <el-form-item prop="repayDate">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.repayDate">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Repayment amount:</span>
              <el-form-item prop="totalRepayAmount">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.totalRepayAmount">
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div>
            <el-button type="primary" :disabled="!checked" @click="onApply">One-Click Signing</el-button>
            <el-checkbox v-model="checked">Agree with AjeGo Terms of Service and Privacy Policy</el-checkbox>
          </div>
        </div>
      </el-form>

      <el-form v-else :model="item" :rules="rules" ref="form">
        <div class="block content">
          <div class="block_box">
            <div class="input">
              <span class="required">Quota:</span>
              <el-form-item prop="applyAmount">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.applyAmount">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Term:</span>
              <el-form-item prop="displayLoanDays">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.displayLoanDays">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Transaction amount:</span>
              <el-form-item prop="loanAmount">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.loanAmount">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Interest:</span>
              <el-form-item prop="serviceFees">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.serviceFees">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Repayment date:</span>
              <el-form-item prop="repayDate">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.repayDate">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span class="required">Repayment amount:</span>
              <el-form-item prop="totalRepayAmount">
                <el-input
                  disabled
                  placeholder="Please enter"
                  v-model="item.totalRepayAmount">
                </el-input>
              </el-form-item>
            </div>
            <div class="input">
              <span>Status:</span>
              <el-form-item prop="contractStatusName">
                <el-input
                  class="status"
                  disabled
                  placeholder="Please enter"
                  v-model="item.contractStatusName">
                </el-input>
              </el-form-item>
            </div>
            <div class="input"></div>
          </div>
          <div class="text">Easy to manage your loan account information, please download the AjeGo app. Download the AjeGo app</div>
          <el-button type="primary">Download AjeHome app</el-button>
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { geolocation } from '../plugins/index';
import {
  login, validationCode, getUserInfo, contractDetail, getProduct, scheduleCalc, applyFast,
} from '../plugins/api';

export default {
  name: 'QuickApply',
  data() {
    const phoneValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your mobile phone number'));
      } else if (!/^(0|234)?[0-9]{10}$/.test(value.replaceAll(' ', ''))) {
        callback(new Error('Wrong format of mobile phone number'));
      } else {
        callback();
      }
    };
    const codeValidator = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter a verification code'));
      } else if (value.length !== 4) {
        callback(new Error('Incorrect format of CAPTCHA'));
      } else {
        callback();
      }
    };
    return {
      devicePixelRatio: window.devicePixelRatio,
      loading: false,
      checked: false,
      timer: null,
      time: 0,
      form: {
        phone: '',
        code: '',
      },
      item: {
        applyAmount: '',
        displayLoanDays: '',
        loanAmount: '',
        serviceFees: '',
        repayDate: '',
        totalRepayAmount: '',
        contractStatusName: '',
      },
      rules: {
        phone: { validator: phoneValidator, trigger: 'change' },
        code: { validator: codeValidator, trigger: 'change' },
      },
    };
  },
  computed: {
    ...mapState(['accessToken', 'user']),
    isApply() {
      switch (this.user.applyStatus) {
        case 'APPLY':
        case 'SETTLE':
          return true;
        default:
          return false;
      }
    },
  },
  watch: {
    accessToken: {
      handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true,
    },
  },
  created() {

  },
  methods: {
    ...mapMutations(['SET_ACCESS_TOKEN', 'SET_USER']),
    async init() {
      this.loading = true;
      await Promise.all([
        this.getUser(),
      ]);
      this.loading = false;
    },
    async onLogin() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      await this.$axios
        .post(login, {
          loginType: 'CODE_LOGIN',
          deviceId: 'H5',
          gps: await geolocation(),
          phone: this.form.phone.replaceAll(' ', ''),
          validateCode: this.form.code,
          isValidateCode: 'Y',
        })
        .then((e) => {
          if (e.status.code === '000') {
            this.SET_ACCESS_TOKEN(e.body.accessToken);
          } else if (e.status.code === '993') {
            this.$router.push('/UserTips');
          } else {
            this.$message.error(e.status.msg);
          }
        })
        .catch(() => {});
      this.loading = false;
    },
    async onApply() {
      this.loading = true;
      await this.$axios
        .post(applyFast, {
          phone: this.user.phone.replaceAll(' ', ''),
          org: '400000000001',
          channel: this.$route.query.channel || localStorage.channel || 'Fast-Link',
        })
        .then((e) => {
          if (e.status.code === '000') {
            return this.getUser();
          }
          this.$message.error(e.status.msg);
        })
        .catch(() => {});
      this.loading = false;
    },
    async getUser() {
      await this.$axios({
        method: 'get',
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code === '000') {
          this.SET_USER(e.body);
          if (e.body.continuedLoan === 'Y') {
            if (this.isApply) {
              return this.getProduct();
            }
            return this.getContractDetail();
          }
          this.$router.push('/UserTips');
        }
      }).catch(() => {});
    },
    async getContractDetail() {
      await this.$axios({
        method: 'get',
        url: contractDetail,
        params: {
          contractNo: this.user.applyId,
        },
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.item.applyAmount = e.body.loanAmount;
            this.item.displayLoanDays = e.body.displayLoanDays;
            this.item.loanAmount = e.body.actulAmount;
            this.item.serviceFees = e.body.serviceFee;
            this.item.repayDate = e.body.repaymentDate;
            this.item.totalRepayAmount = e.body.payTotalAmount;
            this.item.contractStatusName = e.body.statusName;
          }
        })
        .catch(() => {});
    },
    async getProduct() {
      await this.$axios({
        method: 'get',
        url: getProduct,
        data: {},
      }).then((e) => {
        if (e.status.code === '000') {
          return this.scheduleCalc(e.body);
        }
      }).catch(() => {});
    },
    async scheduleCalc(data) {
      const product = data.products[data.products.length - 1];
      await this.$axios({
        method: 'POST',
        url: scheduleCalc,
        data: {
          loanAmt: data.maxAmount,
          loanDay: product.loanTerms,
          loanCode: product.loanCode,
          productCode: product.productCode,
          displayLoanDays: product.displayLoanDays,
        },
      }).then((e) => {
        if (e.status.code === '000') {
          this.item.applyAmount = data.maxAmount;
          this.item.displayLoanDays = product.displayLoanDays;
          this.item.loanAmount = e.body.actualAmount;
          this.item.serviceFees = e.body.serviceFee;
          this.item.repayDate = e.body.loanPmtDueDate;
          this.item.totalRepayAmount = e.body.totalAmt;
        }
      }).catch(() => {});
    },
    async getSmsCode(sendChannel) {
      if (this.time) return;
      this.$refs.form.validateField('phone', (valid) => {
        if (!valid) {
          this.time = 90;
          this.$axios({
            method: 'get',
            url: validationCode,
            params: {
              phone: this.form.phone.replace(/\s/g, ''),
              type: 'LOGIN',
              sendChannel,
            },
          })
            .then((e) => {
              if (e.status.code === '000') {
                if (e.body.kaptcha) {
                  this.$message({
                    message: e.body.kaptcha,
                    duration: 10000,
                  });
                }

                this.timer = setInterval(() => {
                  this.time -= 1;
                  if (this.time <= 0) {
                    this.time = 0;
                    clearInterval(this.timer);
                  }
                }, 1000);
              } else {
                this.time = 0;
                this.$message.error(e.status.msg);
              }
            })
            .catch(() => {
              this.time = 0;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {}
.page {
  @include pixel-ratio();
  .status {
    /deep/ input {
      background: rgba(103,45,158,0.15);
      color: #672D9E;
      border: 0px;
    }
  }
  .block {
    text-align: left;
    .block_box {
      display: flex;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
     font-size: 13px;
     color: #999999;
     padding: 10px 0px;
    }
    > .el-button {
      margin-top: 30px;
    }
    .el-form-item {
      display: inline-block;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }

    &.bar {
      position: relative;
      background: white;
      .bar_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .bar_content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .bar_value {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 0px 10px;
          max-width: 1200px;
          margin: 0 auto;
          .hint {
            max-width: 630px;
          }
        }
      }
    }

    &.content {
      padding-top: 60px;
      padding-bottom: 30px;
      text-align: center;
      .block_box {
        display: block;
        max-width: 800px;
      }
      .input {
        display: inline-block;
        width: 50%;
        span {
          min-width: 155px;
          display: inline-block;
          text-align: right;
          font-size: 12px;
          color: #666666;
        }
        .el-input {
          vertical-align: middle;
          width: auto;
          margin-left: 10px;
          width: 220px;
        }
        &:nth-child(2n + 1) {
          text-align: right;
        }
      }
      > .el-button {
        min-width: 250px;
        margin-top: 40px;
      }
      .el-checkbox {
        display: block;
        color: #666666;
        font-size: 12px;
        margin-top: 8px;
        margin-bottom: 20px;
        white-space: normal;
      }
    }

  }

  @media screen and (max-width: 800px) {
    .block {
      text-align: center;
      .block_box {
        display: inline-block;
      }
      .el-form-item {
        display: block;
      }
      &.bar {
        min-height: 600px;
        .bar_img {
          position: absolute;
          top: 100px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(0deg,rgba(0,0,0,0.00)0%,#ffffff 100%);
          }
          img {
            height: 100%;
            width: auto;
            position: absolute;
            right: 0px;
            transform: translateX(22%);
          }
        }
        .title {
          font-size: 26px;
        }
        .el-button {
          margin-top: 330px;
        }
        .bar_content {
          padding-top: 30px;
          padding-bottom: 20px;
          .bar_value {
            top: 10px;
            transform: none;
          }
        }
      }
      &.content {
        margin-top: 0px;
        padding: 0px 10px;
        padding-top: 20px;
        padding-bottom: 30px;
        .input {
          width: 100%;
          padding: 0px;
          text-align: left;
          span {
            text-align: left;
            margin-bottom: 8px;
          }
          .el-input {
            margin-left: 0px;
            width: 100%;
          }
          &:nth-child(2n + 1) {
            text-align: left;
          }
        }
        .el-button {
          min-width: 80%;
        }
      }

    }
  }
}
</style>
